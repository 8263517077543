var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.cats, function(types, category) {
      return _c("div", { key: category }, [
        _c("h3", { staticClass: "attribute-category" }, [
          _vm._v(_vm._s(_vm.categoryDescriptions[category]))
        ]),
        _c(
          "table",
          { staticClass: "table table-bordered table-responsive" },
          _vm._l(_vm.filterTypes(types), function(values, type) {
            return _c("tr", { key: type }, [
              _c("th", { staticClass: "text-nowrap" }, [_vm._v(_vm._s(type))]),
              _c(
                "td",
                { attrs: { valign: "middle" } },
                _vm._l(values, function(value, index) {
                  return _c("p", {
                    key: index,
                    domProps: { innerHTML: _vm._s(_vm.anchorme(value)) }
                  })
                }),
                0
              )
            ])
          }),
          0
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }